<template>
  <div id="app">
    <!-- <naviga-tion-vue /> -->
    <router-view />
    <!-- <footer-view-vue /> -->
    <!-- <el-backtop :bottom="100" :visibility-height="50"></el-backtop> -->
  </div>
</template>
<script>
// import FooterViewVue from "./components/FooterView.vue";
// import NavigaTionVue from "./components/NavigaTion.vue";
export default {
  name: "HomeView",
  components: {
    // NavigaTionVue,
    // FooterViewVue,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
html,
body,
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}
</style>
