import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie' //我这里需要，所以引入轻量级的处理cookies的js
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import zhLocale from './zh'     //本地创建的中文环境配置文件
import enLocale from './en'     //本地创建的英文环境配置文件

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  }
}
//获取当前语言环境，通过后台返回的语言或者浏览器语言环境
export function getLanguage () {
  const chooseLanguage = Cookies.get('language')//取后台设置的语言
  if (chooseLanguage) return chooseLanguage
  //如果后台没有返回语言则根据浏览器的语言环境返回语言变量
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)//获取前端设置的所有语言
  //遍历所有语言值组成的数组，匹配前端设置的语言能匹配到就返回改语言值
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  //如果都没匹配到就直接返回英文
  return 'en'
}
// function loadLocaleMessages () {
//   const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

const i18n = new VueI18n({
  //语言标识 this.$i18n.locale 通过切换locale的值来实现语言切换
  //如：this.$i18n.locale='en' 直接切换成英文，仅限于配置了语言的一些变量
  locale: getLanguage(),//调的上边这个函数
  messages              //上边配置的语言标识对应的不同配置
})

export default i18n