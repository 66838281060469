import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/IndexView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '极转网贷' }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue'),
    meta: { keepAlive: true }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/call',
    name: 'call',
    component: () => import(/* webpackChunkName: "call" */ '../views/CallView.vue')
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import(/* webpackChunkName: "goods" */ '../views/GoodsDetail.vue')
  },

]

const router = new VueRouter({
  // mode: 'hash', // 或者 'history'
  routes,
  scrollBehavior: () => ({ y: 0 }),
})
// 路由前置导航守卫
router.beforeEach((to, from, next) => {
  // 根据路由元信息设置文档标题
  window.document.title = to.meta.title || '广州安护健医疗用品有限公司 - 官网'
  next()
})

export default router
