import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './lang/i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import 'animate.css';
import axios from 'axios'
Vue.config.productionTip = false

axios.defaults.baseURL = 'https://api.yansaker.cn/'
Vue.prototype.$http = axios

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)// 在注册Element时设置i18n的处理方法
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')


