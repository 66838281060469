
export default {
  login: { // 登录模块
    title: '系统登录',
    loginName: '用户名',
    loginNamePlaceholder: '请输入用户名',
    password: '密码',
    passwordPlaceholder: '请输入密码',
    logIn: '登录',
  },
}