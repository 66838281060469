 <template>
    <div class="text-body">
     
        <div class="container">
        <el-form class="text">
          <el-form-item >
            <el-image  :src="img" :fit="fit" />
        </el-form-item>
        <el-form-item label="问题反馈：">
          <el-input v-model="msg.text" autocomplete="off"></el-input>
        </el-form-item>
          <el-form-item class="text-put">
          <el-button type="primary" class="Btn" @click="posttext"
          >确认提交</el-button
        >
        </el-form-item>
      </el-form>
    </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
          img: require("../assets/4042.jpg"),
          msg: {
            text:''
          }
        };
      },
      methods: {
          async posttext() {
            try {
    const response = await this.$http.post('/puttext/add', this.msg);
    if(response.data.code==200){
        this.$message.success('提交成功');
        this.msg.text='';
    console.log(response.data); // 处理响应数据
    }
  
  } catch (error) {
    console.error(error); // 处理错误情况
  }
    },
      }

    }
</script>
<style lang="less" >
.container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh; /* 容器高度为视口高度 */
}

.text{
    width: 50%;
}
.text-put{
    text-align: center;
}
</style>